*, :before, :after {
  box-sizing: border-box;
}

body, p, h1, h2, h3, h4, h5, h6, input {
  margin: 0;
}

@font-face {
  font-family: Roboto;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-regular.eot"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-regular.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-regular.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-regular.woff") format("woff"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-regular.ttf") format("truetype"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-regular.svg#robotoregular") format("svg");
}

@font-face {
  font-family: Roboto;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.eot"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.woff") format("woff"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.ttf") format("truetype"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.svg#robotobold") format("svg");
}

@font-face {
  font-family: Montserrat;
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url("//img2.cncenter.cz/fonts/Montserrat/eot/Montserrat-Black.eot"), url("//img2.cncenter.cz/fonts/Montserrat/eot/Montserrat-Black.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/Montserrat/woff/Montserrat-Black.woff") format("woff"), url("//img2.cncenter.cz/fonts/Montserrat/woff2/Montserrat-Black.woff2") format("woff2");
}

body {
  color: #261400;
  font-family: Roboto, arial, sans-serif;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

a {
  color: #ca2226;
}

.visually-hidden {
  clip: rect(1px 1px 1px 1px) !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  white-space: nowrap !important;
  width: 1px !important;
  height: 1px !important;
  position: absolute !important;
  overflow: hidden !important;
}

.container {
  width: 1080px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
}

.main-container {
  background: #fff;
}

.text-red {
  color: #ca2226;
}

.page-main {
  position: relative;
}

.fullwidth-image-wrapper {
  overflow: hidden;
}

.fullwidth-image {
  width: 100vw;
  height: 270px;
  display: block;
  position: relative;
  left: calc(-50vw + 50%);
}

.fullwidth-image img {
  object-fit: cover;
  height: 100%;
}

.main-title {
  text-align: center;
  color: #ca2226;
  margin-bottom: 40px;
  font-family: Montserrat, arial, sans-serif;
  font-size: 35px;
  font-weight: 700;
  line-height: 43px;
}

.grecaptcha-badge {
  visibility: hidden;
}

.page-navigation {
  background-color: #ca2226;
  border-bottom: 5px solid #eec264;
}

.page-navigation__list {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 17px 0 13px;
  list-style: none;
  display: flex;
  position: relative;
}

.page-navigation__item {
  margin-right: 40px;
}

.page-navigation__item:nth-last-child(2) {
  margin-right: 0;
}

.page-navigation__item:last-child {
  margin-right: 0;
  padding-top: 3px;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.page-navigation__link {
  text-transform: uppercase;
  color: #fff;
  font-family: Roboto, arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  display: block;
}

.page-navigation__link:hover {
  text-decoration: underline;
}

.header-image {
  text-align: center;
  margin-bottom: -55px;
  position: relative;
}

.header-image__logo {
  z-index: 1;
  display: block;
  position: absolute;
  top: 45px;
  left: 30px;
}

.main-leading {
  text-align: center;
  margin-bottom: 25px;
  position: relative;
}

.main-leading__title {
  text-transform: uppercase;
  color: #ca2226;
  margin: 0 0 20px;
  font-family: Montserrat, arial, sans-serif;
  font-size: 35px;
  font-weight: 900;
  line-height: 43px;
}

.main-leading__description {
  max-width: 700px;
  margin: 0 auto;
  font-size: 17px;
  line-height: 23px;
}

.prize-sticker {
  text-transform: uppercase;
  background: #fc0;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  padding: 4px 0 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  display: flex;
}

.main-prize {
  border: 5px solid #fc0;
  border-radius: 20px;
  justify-content: space-between;
  align-items: center;
  width: 610px;
  max-width: 100%;
  margin: 0 auto 30px;
  padding: 20px;
  display: flex;
}

.main-prize__body {
  text-align: center;
  flex-direction: column;
  align-items: center;
  width: 270px;
  margin-left: auto;
  padding-left: 10px;
  display: flex;
}

.main-prize__sticker {
  margin-bottom: 15px;
}

.main-prize__sticker-compete {
  background-image: url("//img2.cncenter.cz/images/blesk-jarmark/sticker-compete.png");
  background-repeat: no-repeat;
  width: 221.7px;
  height: 130px;
  display: none;
}

.main-prize__sticker-compete--visible {
  display: block;
}

.main-prize__text {
  max-width: 100%;
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 29px;
}

.main-prize__button {
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  background: #ca2226;
  border: none;
  border-radius: 5px;
  padding: 14px 24px 15px;
  font-family: Roboto, arial, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.main-prize__button:hover {
  opacity: .8;
}

.main-prize__button:active {
  opacity: .6;
}

.main-prize__button:focus {
  outline: none;
}

.main-prize__button:focus-visible {
  outline: -webkit-focus-ring-color auto 1px;
}

.prizes__list {
  flex-wrap: wrap;
  margin: -35px 0 0 -15px;
  padding: 33px 0 0 15px;
  list-style: none;
  display: flex;
}

.prizes__item {
  margin: 35px 0 0 13px;
}

.prize-window {
  cursor: pointer;
  background: none;
  border: none;
  flex-direction: column;
  align-items: center;
  width: 200px;
  padding: 10px 30px 0;
  font-family: Roboto, arial, sans-serif;
  display: flex;
  position: relative;
}

.prize-window:focus {
  outline: none;
}

.prize-window:before, .prize-window:after {
  content: "";
  background: url("//img2.cncenter.cz/images/blesk-jarmark/shutter.png") 0 0 / contain no-repeat;
  width: 31px;
  height: 215px;
  position: absolute;
  top: 0;
}

.prize-window:before {
  left: 1px;
}

.prize-window:after {
  right: 1px;
  transform: scaleX(-1);
}

.prize-window--active {
  width: 220px;
  margin-top: -22px;
  margin-right: -20px;
  padding-top: 32px;
  padding-right: 50px;
}

.prize-window--active:before, .prize-window--active:after {
  background-image: url("//img2.cncenter.cz/images/blesk-jarmark/shutter-red.png");
  top: 22px;
}

.prize-window--active:after {
  right: 21px;
}

.prize-window--hidden {
  cursor: auto;
}

.prize-window--hidden:before, .prize-window--hidden:after {
  content: none;
}

.prize-window__sticker {
  z-index: 1;
  width: 64px;
  height: 64px;
  font-size: 13px;
  line-height: 15px;
  position: absolute;
  top: 0;
  right: 0;
}

.prize-window__image {
  border: 1px solid #d1d6f0;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  margin-bottom: 10px;
  display: flex;
  overflow: hidden;
}

.prize-window--active .prize-window__image {
  border-width: 3px;
  border-color: #ca2226;
}

.prize-window--hidden .prize-window__image {
  color: #6d8095;
  background-color: #cbe1ef;
  font-size: 74px;
  font-weight: 700;
}

.prize-window__text {
  color: #9fb5cf;
  max-width: 100%;
  font-size: 14px;
  line-height: 18px;
}

.prize-window--active .prize-window__text {
  color: #ca2226;
  font-weight: 700;
}

.prize-window--hidden .prize-window__text {
  color: #6d8095;
}

.overlay {
  z-index: 3;
  background-color: rgba(38, 40, 89, .8);
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.overlay--show {
  display: block;
}

.overlay__inner {
  justify-content: center;
  padding: 125px 0 175px;
  display: flex;
}

.popup {
  background: #fff;
  border-radius: 20px;
  width: 940px;
  padding: 40px 125px 60px;
  position: relative;
  box-shadow: 0 3px 36px rgba(0, 0, 0, .3);
}

.popup__close-button {
  z-index: 2;
  cursor: pointer;
  background: #ca2226 url("//img2.cncenter.cz/images/blesk-jarmark/close.svg") center / contain no-repeat;
  border: none;
  border-radius: 50%;
  width: 53px;
  height: 53px;
  padding: 0;
  position: absolute;
  top: -26px;
  right: -26px;
}

.article {
  display: none;
  position: relative;
}

.article--show {
  display: block;
}

.article__logo {
  max-width: 200px;
  max-height: 200px;
  margin-bottom: 40px;
  display: block;
}

.article__title {
  margin-bottom: 30px;
  font-size: 30px;
  line-height: 37px;
}

.article__title--red {
  color: #ca2226;
}

.article__title--green {
  color: #2cce42;
}

.article__gallery {
  margin-bottom: 50px;
}

.article__perex {
  margin-bottom: 60px;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
}

.article__text {
  font-size: 14px;
  line-height: 25px;
}

.article__text p {
  margin-bottom: 25px;
}

.article__text p:last-child {
  margin-bottom: 0;
}

.article__bottom-container {
  width: 500px;
  margin: 45px auto 0;
}

.article__bottom-container--winners {
  margin-top: 75px;
}

.article__bottom-container--poll {
  border-top: 1px solid #d6d6d6;
  margin-top: 0;
  padding-top: 45px;
}

.gallery {
  width: 690px;
  height: 365px;
  overflow: hidden;
}

@media (max-width: 690px) {
  .gallery {
    width: 100%;
    height: auto;
  }
}

.gallery__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
  position: relative;
  overflow: hidden;
}

.gallery__list:before {
  content: "";
  z-index: 2;
  background-color: #000;
  background-image: url("//img2.cncenter.cz/images/blesk-2013/loading.svg?v=1");
  background-position: 50% 155px;
  background-repeat: no-repeat;
  background-size: 50px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.gallery__list--show:before {
  content: none;
}

.gallery__item:not(:only-child), .owl-item {
  visibility: hidden;
}

.owl-item.active, .owl-item .gallery__item {
  visibility: visible;
}

.gallery__button {
  z-index: 1;
  cursor: pointer;
  background: rgba(0, 0, 0, .4) url("//img2.cncenter.cz/images/blesk-jarmark/arrow.svg") center / 15px no-repeat;
  width: 50px;
  height: 100%;
  font-size: 0;
  line-height: 0;
  display: block;
  position: absolute;
  top: 0;
}

.gallery__button--prev {
  left: 0;
}

.gallery__button--next {
  right: 0;
  transform: scaleX(-1);
}

.today-prize-info {
  text-align: center;
  padding-bottom: 40px;
  font-weight: 700;
}

.today-prize-info:last-child {
  border-bottom: none;
}

.today-prize-info__title {
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 24px;
}

.today-prize-info__subtitle {
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 25px;
}

.today-prize-info__image-wrapper {
  border: 4px solid #fc0;
  border-radius: 20px;
  width: 168px;
  height: 168px;
  margin: 0 auto;
  display: block;
  overflow: hidden;
}

.form-element {
  color: #494d5c;
  width: 100%;
  font-size: 14px;
  line-height: 24px;
  position: relative;
}

.form-element:after {
  white-space: nowrap;
}

.form-element:before {
  position: absolute;
  top: calc(50% + 14px);
  left: calc(100% + 5px);
  transform: translateY(-50%);
}

.form-element[data-invalid] {
  color: #ca2226;
}

.form-element--half-width {
  width: 235px;
}

.form-element--quarter-width {
  width: 117px;
}

.form-element--radio {
  padding: 2px 0 0 32px;
  font-size: 16px;
  line-height: 25px;
}

.form-element--checkbox {
  width: auto;
  margin-top: 25px;
  margin-bottom: 15px;
  padding-left: 30px;
  font-size: 12px;
  line-height: 16px;
}

.form-element--checkbox[data-invalid] {
  color: inherit;
}

.form-element sup {
  color: #ca2226;
  line-height: 0;
}

.form-element a {
  color: inherit;
  font-weight: 700;
}

.form-element[data-hint]:after {
  content: attr(data-hint);
  color: #8f919b;
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  top: calc(100% + 3px);
  left: 0;
}

.form-element[data-invalid="empty"]:after {
  content: attr(data-error-empty);
  color: #ca2226;
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  top: calc(100% + 3px);
  left: 0;
}

.form-element[data-invalid="pattern"]:after {
  content: attr(data-error-pattern);
  color: #ca2226;
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  top: calc(100% + 3px);
  left: 0;
}

.form-element[data-invalid="format"]:after {
  content: attr(data-error-format);
  color: #ca2226;
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  top: calc(100% + 3px);
  left: 0;
}

.form-element[data-invalid="empty"]:before, .form-element[data-invalid="pattern"]:before, .form-element[data-invalid="format"]:before {
  content: "!";
  color: #ca2226;
  font-size: 28px;
  font-weight: 700;
}

.form-element[data-invalid="checkbox"]:after {
  content: attr(data-error-checkbox);
  color: #ca2226;
  margin-left: 30px;
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  top: 100%;
  left: 0;
}

.form-element__wrapper[data-invalid="radio"]:after {
  content: attr(data-error-radio);
  color: #ca2226;
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  top: calc(100% + 3px);
  left: 0;
  transform: translateY(5px);
}

.form-element__pseudo {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.form-element__pseudo--radio {
  border: 2px solid #fff;
  border-radius: 50%;
  width: 19px;
  height: 19px;
  box-shadow: 0 0 0 1px #707070;
}

[type="radio"]:checked ~ .form-element__pseudo--radio {
  background-color: #ca2226;
}

.form-element__pseudo--checkbox {
  border: 1px solid #707070;
  border-radius: 5px;
  width: 21px;
  height: 21px;
}

[type="checkbox"]:checked ~ .form-element__pseudo--checkbox {
  background-image: url("//img2.cncenter.cz/images/blesk-jarmark/check-grey.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 12px;
}

.form-element[data-invalid] .form-element__pseudo--checkbox {
  border-color: #ca2226;
}

.form-element__input {
  color: #494d5c;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  width: 100%;
  height: 48px;
  padding: 0 15px;
  font-size: 14px;
  font-weight: 700;
  line-height: 46px;
}

.form-element__input:focus {
  border: 1px solid #000;
  outline: none;
}

.form-element[data-invalid] .form-element__input {
  border-color: #ca2226;
  box-shadow: 0 0 0 1px #ca2226;
}

.form-element__wrapper {
  position: relative;
}

.competition-poll--hide, .article[data-state="waiting"] .competition-poll {
  display: none;
}

.competition-poll__title {
  margin-bottom: 15px;
}

.competition-poll__date {
  margin-bottom: 40px;
  font-size: 14px;
}

.competition-poll__question {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
}

[data-invalid] .competition-poll__question {
  color: #ca2226;
}

.competition-poll__answers {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}

.competition-poll__answer {
  margin-bottom: 12px;
}

.competition-poll__answer:last-child {
  margin-bottom: 0;
}

.competition-poll__answer--correct {
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
}

.poll-winners {
  text-align: center;
  border-top: 1px solid #d6d6d6;
  padding-top: 45px;
  position: relative;
}

.poll-winners:before {
  content: "";
  background: #fff url("//img2.cncenter.cz/images/blesk-jarmark/cup.svg") center / contain no-repeat;
  width: 80px;
  height: 48px;
  position: absolute;
  top: -24px;
  left: 50%;
  transform: translateX(-50%);
}

.poll-winners__title {
  margin-bottom: 32px;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.poll-winners ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.poll-winners li {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 20px;
}

.poll-winners li:last-child {
  margin-bottom: 0;
}

.competition-form {
  margin: 85px -32px 0 0;
  display: none;
  position: relative;
}

.article--active.article--show ~ * .competition-form--show {
  flex-wrap: wrap;
  display: flex;
}

.competition-form .form-element {
  width: 100%;
  margin-bottom: 25px;
  margin-right: 32px;
}

.competition-form .form-element--half-width {
  width: 234px;
}

.competition-form .form-element--quarter-width {
  width: 117px;
}

.competition-form__title {
  margin-bottom: 5px;
  font-size: 22px;
  line-height: 30px;
}

.competition-form__title--red {
  color: #ca2226;
}

.competition-form__title--green {
  color: #2cce42;
}

.competition-form__subtitle {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

.competition-form__fill-invitation {
  margin-bottom: 30px;
  font-weight: 700;
}

.competition-form__button {
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  background: #ca2226;
  border: none;
  border-radius: 100px;
  margin: 0 auto;
  padding: 0 44px;
  font-family: inherit;
  font-size: 14px;
  line-height: 48px;
  position: relative;
}

.competition-form__button:hover {
  opacity: .8;
}

.competition-form__button:active {
  opacity: .6;
}

.competition-form__button:focus {
  outline: none;
}

.competition-form__button:focus-visible {
  outline: -webkit-focus-ring-color auto 1px;
}

.competition-form__button--loading:after {
  content: "";
  background-image: url("//img2.cncenter.cz/images/newblesk/loader.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: calc(100% + 10px);
  transform: translateY(-50%);
}

.competition-form__post-message {
  margin-top: 10px;
  display: none;
}

.article--active.article--show ~ * .competition-form__post-message--show {
  display: block;
}

.competition-form__post-message--error {
  margin-top: 50px;
}

.competition-form__post-message--show + .competition-form__post-message--error {
  display: none;
}

.article-page {
  width: 690px;
  margin: 0 auto;
  padding-bottom: 50px;
  position: relative;
}

@media (max-width: 690px) {
  .article-page {
    width: 100%;
  }
}

.article-page__title {
  text-transform: none;
}

.article-page__content {
  font-size: 14px;
  line-height: 25px;
}

.article-page__content p {
  margin-bottom: 25px;
}

.article-page__content p:last-child {
  margin-bottom: 0;
}

.article-page__content h3 {
  font-size: 24px;
  line-height: 38px;
}

.article-page__content ul {
  margin: 35px 0 25px;
  padding: 0;
  list-style: none;
}

.article-page__content li {
  margin-bottom: 25px;
  padding-left: 30px;
  position: relative;
}

.article-page__content li:last-child {
  margin-bottom: 0;
}

.article-page__content li:before {
  content: "";
  background-color: #ca2226;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 9px;
  left: 10px;
}

.recentarticles {
  width: 690px;
  margin: 0 auto;
  padding-bottom: 50px;
}

.recentarticles__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.recentarticles__item {
  margin-bottom: 30px;
}

.recentarticles__item:last-child {
  margin-bottom: 0;
}

.recentarticle {
  border-bottom: 1px solid #d6d6d6;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 30px;
  display: flex;
}

.recentarticle:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.recentarticle__image-link {
  flex-shrink: 0;
  margin-right: 20px;
}

.recentarticle__text {
  color: inherit;
  font-size: 25px;
  font-weight: 700;
  line-height: 28px;
  text-decoration: none;
}

.recentarticle__text:hover {
  text-decoration: underline;
}

.winners-page {
  text-align: center;
  width: 350px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 25px;
}

.winners-page__date {
  color: #ca2226;
  margin-bottom: 10px;
  font-weight: 700;
}

.winners-page ul {
  border-bottom: 1px solid #d6d6d6;
  margin: 0 0 25px;
  padding: 0 0 20px;
  list-style: none;
}

.winners-page ul:last-child {
  border: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.winners-page li {
  margin-bottom: 7px;
}

